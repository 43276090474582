import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Site from '../pages/Site';
import Page404 from '../pages/404';
import Prolife from '../scripts/Prolife';

const RoutesApp: React.FC = () => (
  <Routes>
    <Route path="/" element={<Site />} />

    <Route path="/prolife" element={<Prolife />} />

    <Route path="/"  element={<Page404 />} />
  </Routes >
);

export default RoutesApp;
