import React from 'react';
import CountUp from 'react-countup';
import PropTypes from 'prop-types';
import { getYear } from 'date-fns';

import { Container, Title, Description, Stats, StatsCard } from './styles';

import size from '../../../config/windowSizeConfig';

interface AboutProps {
  isActive: boolean;
}

const About: React.FC<AboutProps> = props => {
  const { isActive } = props;
  const { isMobile } = size();

  const years = getYear(new Date()) - 2002;

  return (
    <Container>
      <Title isMobile={isMobile}>
        <h1>Sobre nós</h1>
      </Title>
      <Description isMobile={isMobile}>
        <h2>
          Somos uma empresa que atua no ramo de desenvolvimento de sistemas para
          clientes de todo o porte, analisando sua necessidade e transformando
          ela em solução por meio da tecnologia.
          {!size().isMobile &&
            `A 3R está sempre preocupada em
          levar excelência para seus clientes, com colaboradores capacitados,
          processos claros e transparência, superando assim as expectativas dos
          projetos desenvolvidos.`}
        </h2>
      </Description>
      {isActive && (
        <Stats>
          <StatsCard isMobile={isMobile}>
            <div>
              <CountUp end={years} />
            </div>
            <p>anos de atuação</p>
          </StatsCard>
          <StatsCard isMobile={isMobile}>
            <div>
              <h2>+</h2>
              <CountUp end={800} />
            </div>
            <p>projetos desenvolvidos</p>
          </StatsCard>
          <StatsCard isMobile={isMobile}>
            <div>
              <h2>+</h2>
              <CountUp end={2000} />
            </div>
            <p>usuários ativos</p>
          </StatsCard>
        </Stats>
      )}
    </Container>
  );
};

About.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

export default About;
