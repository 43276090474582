import React, { useEffect } from 'react';

const Prolife: React.FC = () => {
  useEffect(() => {
    const urn = window.location.href.split('/');
    window.location.href = `https://bioteam.3rsistemas.com/recibos/${
      urn[urn.length - 1]
    }`;
  }, []);

  return <div />;
};

export default Prolife;
