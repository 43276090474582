import React, { useRef, useState } from 'react';
import SwiperCore, {
  Mousewheel,
  Keyboard,
  Pagination,
  EffectFade,
  Navigation, 
} from 'swiper';
import Swiper, { ReactIdSwiperProps, SwiperRefNode } from 'react-id-swiper';
import { HamburgerSpin } from 'react-animated-burgers';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { RiWhatsappLine } from 'react-icons/ri';
import { TiSocialFacebook, TiSocialLinkedin } from 'react-icons/ti';

import { position } from 'polished';
import size from '../../config/windowSizeConfig';

import logo from '../../assets/3R_branco_horizontal.png';

import Home from './Home';
import About from './About';
import WhatWeDo from './WhatWeDo';
import Customers from './Customers'; 
import Contact from './Contact';

import {
  Container,
  Menu,
  MenuLogo,
  MenuOptions,
  MenuOptionsButtons,
  MenuOptionsContact,
  ButtonPrevious,
  ButtonNext,
} from './styles';

interface SwiperParams {
  namePrev: string | null;
  nameNext: string | null;
  isBegin: boolean | undefined;
  isEnd: boolean | undefined;
  borderColor: string | null;
}

const Site: React.FC = () => {
  const { isMobile }: { isMobile: boolean | undefined } = size();

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [showMenuOption, setShowMenuOption] = useState(false);
  const [swiper, setSwiper] = useState<SwiperParams>({
    namePrev: null,
    nameNext: 'sobre nós',
    isBegin: true,
    isEnd: false,
    borderColor: ' #212020',
  });
  const [indexWithDelay, setIndexWithDelay] = useState<number>(0);

  const swiperRef = useRef<SwiperRefNode>(null);

  SwiperCore.use([Mousewheel, Keyboard, Navigation, Pagination, EffectFade]);

  function handlerMenu() {
    setMenuIsOpen(!menuIsOpen);
    if (showMenuOption) {
      setTimeout(function changeShowMenuOption() {
        setShowMenuOption(!menuIsOpen);
      }, 800);
    } else {
      setShowMenuOption(!showMenuOption);
    }
  }

  function handlerChangeSwiper(index: number) {
    const menus = ['home', 'sobre nós', 'o que fazemos', 'clientes', 'contato'];
    const borderColor = ['#1f2f3d', '#212020'];
    const swiperChanged: SwiperParams = {
      isBegin: swiperRef.current?.swiper?.isBeginning,
      isEnd: swiperRef.current?.swiper?.isEnd,
      namePrev: menus[index - 1],
      nameNext: menus[index + 1],
      borderColor: index === 0 ? borderColor[1] : borderColor[index % 2],
    };
    setSwiper(swiperChanged);
  }

  function handlerSwiper(index: number, resetMenu = false) {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index);
    }
    handlerChangeSwiper(swiperRef.current?.swiper?.activeIndex as number);
    if (resetMenu) {
      setMenuIsOpen(false);
      setShowMenuOption(false);
    } else handlerMenu();
  }

  function handlerSwiperNext() {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
      handlerChangeSwiper(swiperRef.current?.swiper?.activeIndex as number);
    }
  }

  function handlerSwiperPrevious() {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
      handlerChangeSwiper(swiperRef.current?.swiper?.activeIndex as number);
    }
  }

  const params: ReactIdSwiperProps = {
    direction: 'vertical',
    mousewheel: true,
    speed: 600,
    effect: 'fade',
    autoHeight: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    keyboard: {
      enabled: true,
      onlyInViewport: false,
    },
    on: {
      slideChange: () => {
        handlerChangeSwiper(swiperRef.current?.swiper?.activeIndex as number);
        setInterval(
          () =>
            setIndexWithDelay(
              swiperRef.current?.swiper?.activeIndex !== undefined
                ? swiperRef.current?.swiper?.activeIndex
                : 0,
            ),
          700,
        );
      },
    },
  };

  return (
    <Container>
      <Menu
        isMobile={isMobile}
        borderColor={swiper.borderColor}
        menuIsOpen={menuIsOpen}
      >
        <MenuLogo type="button" onClick={() => handlerSwiper(0, true)}>
          <img src={logo} alt="logo" />
        </MenuLogo>
        <HamburgerSpin
          toggleButton={handlerMenu}
          isActive={menuIsOpen}
          buttonWidth={36}
          barColor="#FFF"
        />
      </Menu>
      {showMenuOption && (
        <MenuOptions isMobile={isMobile} menuIsOpen={menuIsOpen}>
          <MenuOptionsButtons isMobile={isMobile}>
            <button type="button" onClick={() => handlerSwiper(0)}>
              Home
            </button>
            <button type="button" onClick={() => handlerSwiper(1)}>
              Sobre nós
            </button>
            <button type="button" onClick={() => handlerSwiper(2)}>
              O que fazemos
            </button>
            <button type="button" onClick={() => handlerSwiper(3)}>
              Clientes
            </button>
            <button type="button" onClick={() => handlerSwiper(4)}>
              Contato
            </button>
          </MenuOptionsButtons>
          {!isMobile && (
            <MenuOptionsContact>
              <h2>Estrela</h2>
              <h1>atendimento@3rsistemas.com.br</h1>
              <h1>
                <RiWhatsappLine size={24} color="#fff" />
                (51) 9 9186-9950
              </h1>
              <h1>(51) 3712-7282</h1>
              <h2>RS 129, 427. Arroio do Ouro</h2>
              <h2>Rio Grande do Sul, 95880-000</h2>
              <div>
                <a
                  href="https://facebook.com/3rSistemas/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <TiSocialFacebook size={34} color="#fff" />
                </a>
                <a
                  href="https://www.linkedin.com/company/3r-sistemas-personalizados"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <TiSocialLinkedin size={34} color="#fff" />
                </a>
              </div>
            </MenuOptionsContact>
          )}
        </MenuOptions>
      )}
      {!swiper.isBegin && (
        <ButtonPrevious isMobile={isMobile} onClick={handlerSwiperPrevious}>
          {swiper.namePrev}
          <FiChevronUp size={22} color="#fff" />
        </ButtonPrevious>
      )}
      <Swiper ref={swiperRef} {...params}>
        <div>
          <Home />
        </div>
        <div
          style={
            indexWithDelay === 1
              ? { position: 'fixed' }
              : { position: 'static' }
          }
        >
          <About isActive={swiperRef.current?.swiper?.activeIndex === 1} />
        </div>
        <div
          style={
            indexWithDelay === 2
              ? { position: 'fixed' }
              : { position: 'static' }
          }
        >
          <WhatWeDo />
        </div>
        <div
          style={
            indexWithDelay === 3
              ? { position: 'fixed' }
              : { position: 'static' }
          }
        >
          <Customers />
        </div>
        <div>
          <Contact />
        </div>
      </Swiper>
      {!swiper.isEnd && (
        <ButtonNext isMobile={isMobile} onClick={handlerSwiperNext}>
          {swiper.nameNext}
          <FiChevronDown size={22} color="#fff" />
        </ButtonNext>
      )}
    </Container>
  );
};

export default Site;
