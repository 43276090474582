import styled from 'styled-components';

interface ContainerProps {
  isMobile: boolean | undefined;
}

export const Container = styled.div<ContainerProps>`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding: ${props => (props.isMobile ? '50px' : '100px')};
  h1 {
    color: #d5d8dc;
    letter-spacing: ${props => (props.isMobile ? '5px' : '32px')};
    font-size: ${props => (props.isMobile ? '64px' : '128px')};
  }
  a {
    color: #d5d8dc;
    margin-top: ${props => (props.isMobile ? '15px' : '30px')};
    margin-right: ${props => (props.isMobile ? '4px' : '32px')};
    font-size: ${props => (props.isMobile ? '24px' : '32px')};
  }
`;
