import styled from 'styled-components';

interface TitleProps {
  isMobile: boolean | undefined;
}

interface ContentProps {
  isMobile: boolean | undefined;
}

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #2e2c2c;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 40px 0 40px;
`;

export const Title = styled.div<TitleProps>`
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  h1 {
    font-size: ${props => (props.isMobile ? '32px' : '40px')};
    padding: 0 5px;
    color: #fff;
    background: linear-gradient(to bottom, transparent 65%, #a53234 35%);
  }
`;

export const Content = styled.div<ContentProps>`
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ContentDescription = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 60px;
  padding-right: 24px; 
  h2 {
    color: #fff;
    text-align: justify;
    font-weight: normal;
    line-height: 1.7;
    font-size: 32px;
  }
`;

export const ContentFadePictures = styled.div<ContentProps>`
  width: ${props => (props.isMobile ? '100%' : '60%')};
  height: ${props => (props.isMobile ? '80%' : '95%')};
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: '80%';
      width: '80%';
    }
  }
`;

export const ContentPictures = styled.div<ContentProps>`
  width: ${props => (props.isMobile ? '100%' : '60%')};
  height: ${props => (props.isMobile ? '80%' : '97%')};
  display: flex;
  flex-direction: column;
  div {
    width: 100%;
    height: 33.3%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1px;
    div {
      margin-right: 1%;
      width: 33.3%;
      height: 100%;
      img {
        filter: grayscale(0.3);
        max-width: 80%;
      }
    }
  }
`;
