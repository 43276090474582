import styled from 'styled-components';

interface TitleProps {
  isMobile: boolean | undefined;
}

interface DescriptionProps {
  isMobile: boolean | undefined;
}
interface StatsCardProps {
  isMobile: boolean | undefined;
}

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #2e2c2c;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 40px 0 40px;
`;

export const Title = styled.div<TitleProps>`
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  h1 {
    font-size: ${props => (props.isMobile ? '32px' : '40px')};
    padding: 0 5px;
    color: #fff;
    background: linear-gradient(to bottom, transparent 65%, #a53234 35%);
  }
`;

export const Description = styled.div<DescriptionProps>`
  width: 100%;
  height: 35%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  h2 {
    padding-right: 15px;
    text-align: justify;
    line-height: 1.7;
    font-size: ${props => (props.isMobile ? '16px' : '24px')};
    font-weight: normal;
    color: #fff;
  }
`;

export const Stats = styled.div`
  width: 100%;
  height: 25%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const StatsCard = styled.div<StatsCardProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 30%;
  height: 100%;
  padding-top: ${props => !props.isMobile && '20px'};
  div {
    display: flex;
    flex-direction: row;
    span {
      font-size: ${props => (props.isMobile ? '22px' : '40px')};
      color: #a53234;
      font-weight: bold;
      margin-bottom: 10px;
    }
    h2 {
      font-size: ${props => (props.isMobile ? '22px' : '40px')};
      color: #a53234;
      font-weight: bold;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
  p {
    text-align: center;
    font-size: ${props => (props.isMobile ? '14px' : '24px')};
    color: #fff;
  }
`;
