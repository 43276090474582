import React, { useState, useEffect } from 'react';
import { FaCogs, FaSitemap } from 'react-icons/fa';

import {
  Container,
  Title,
  Content,
  Services,
} from './styles';

import logoEasy from '../../../assets/easy2_branco_sologo.png';
import logoEasyVerde from '../../../assets/easy_verde_sologo.svg';

import size from '../../../config/windowSizeConfig';

const WhatWeDo: React.FC = () => {
  const { isMobile } = size();

  const [serviceSelected, setServiceSelected] = useState(0);

  function handlerService(type: number) {
    setServiceSelected(type);
  }

  useEffect(() => {
    handlerService(0);
  }, []);

  return (
    <Container>
      <Title isMobile={isMobile}>
        <h1>O que fazemos</h1>
      </Title>
      <Content isMobile={isMobile}>

        <Services isMobile={isMobile}>
          <img src={logoEasyVerde} alt="easy" />
          <h1>Sistema de gestão Easy</h1>
          {
            isMobile ? (<p>O Easy é nosso ERP para uma gestão eficaz do seu negócio</p>) : 
            (<p>O Easy é nosso ERP para que empresas organizem de forma eficaz a gestão de seu negócio</p>)
          }
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://easy.3rsistemas.com"
          >
            {isMobile ? ('Site') : ('Acesse o site')}
          </a>
        </Services>
        <Services isMobile={isMobile}>
          <FaSitemap size={60} color="#fff" />
          <h1>Sistemas customizaveis</h1>
          {
            isMobile ? (<p>Criamos sistemas desktop, web e aplicativos móveis sob medida</p>) : 
            (<p>Criamos sistemas desktop, web e aplicativos móveis sob medida, de acordo com sua necessidade</p>)
          }
        </Services>

        {/*
        <Services isMobile={isMobile}>
          <ServicesButton
            isMobile={isMobile}
            isSelected={serviceSelected === 0}
            type="button"
            onClick={() => handlerService(0)}
          >
            {!isMobile && (
              <ServicesButtonIcon>
                <FaCogs size={60} color="#fff" />
              </ServicesButtonIcon>
            )}
            <ServicesButtonDescription isMobile={isMobile}>
              <h1>Desenvolvimento de sistemas customizaveis</h1>
              <h2>{`saiba mais >`}</h2>
            </ServicesButtonDescription>
          </ServicesButton>
          <ServicesButton
            isMobile={isMobile}
            isSelected={serviceSelected === 1}
            type="button"
            onClick={() => handlerService(1)}
          >
            {!isMobile && (
              <ServicesButtonIcon>
                <img src={logoEasy} alt="easy" />
              </ServicesButtonIcon>
            )}
            <ServicesButtonDescription isMobile={isMobile}>
              <h1>easy. Sistema de gestão</h1>
              <h2>{`saiba mais >`}</h2>
            </ServicesButtonDescription>
          </ServicesButton>
        </Services>
        <ServiceDescription selected={serviceSelected} isMobile={isMobile}>
          {serviceSelected === 0 ? (
            <>
              <h1>
                Criamos sistemas
                {` `}
                <strong>desktop</strong>
                {`, `}
                <strong>web</strong>
                {` e `}
                <strong>aplicativos móveis</strong>
                {` `}
                sob medida, de acordo com sua necessidade.
              </h1>
            </>
          ) : (
            <>
              <h1>
                {`O easy é o nosso `}
                <strong>ERP</strong>
                {` para que as empresas `}
                <strong>organizem de forma eficaz</strong>
                {` a gestão de seu negócio.`}
              </h1>
              <h1>
                Acesse em
                {` `}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://easy.3rsistemas.com"
                >
                  easy.3rsistemas.com
                </a>
              </h1>
            </>
          )}
        </ServiceDescription>
        */}

      </Content>
    </Container>
  );
};

export default WhatWeDo;
