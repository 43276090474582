import styled, { css, keyframes } from 'styled-components';

interface MenuProps {
  menuIsOpen: boolean;
  borderColor: string | null;
  isMobile: boolean | undefined;
}

interface MenuOptionsProps {
  isMobile: boolean | undefined;
  menuIsOpen: boolean;
}

interface MenuOptionsButtonsProps {
  isMobile: boolean | undefined;
}

interface ButtonPrevNextProps {
  isMobile: boolean | undefined;
}

const openMenuOptions = keyframes`
  0% {
    transform: translateX(100vw);
  }
  100% {
    transform: translateX(0);
  }
`;

const closeMenuOptions = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100vw);
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  position: fixed;
  .swiper-slide {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
`;

export const Menu = styled.div<MenuProps>`
  position: fixed;
  width: 100%;
  max-width: 100%;
  height: 100px;
  z-index: 99;
  background: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${props => (props.isMobile ? '0 20px' : '0 40px')};
  ${props =>
    !props.menuIsOpen &&
    css`
      border-bottom: 1px solid ${props.borderColor};
    `}
`;

export const MenuLogo = styled.button`
  width: 160px;
  background: transparent;
  border: 0;
  img {
    width: 160px;
  }
`;

export const MenuOptions = styled.div<MenuOptionsProps>`
  width: 100%;
  height: 100%;
  background: #273746;
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 98;
  -webkit-animation: ${props =>
      props.menuIsOpen ? openMenuOptions : closeMenuOptions}
    0.8s;
  animation: ${props => (props.menuIsOpen ? openMenuOptions : closeMenuOptions)}
    0.8s;
`;

export const MenuOptionsButtons = styled.div<MenuOptionsButtonsProps>`
  width: ${props => (props.isMobile ? '100%' : '40%')};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${props => (props.isMobile ? 'center' : 'flex-end')};
  ${props =>
    !props.isMobile &&
    css`
      padding-right: 60px;
    `};
  button {
    background: transparent;
    border: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bold;
    height: 50px;
    margin: 15px 0;
    font-size: 38px;
    letter-spacing: 2px;

    &:hover {
      background: linear-gradient(to bottom, transparent 55%, #a53234 45%);
      transition: background 0.3s ease-in;
    }
  }
`;

export const MenuOptionsContact = styled.div`
  width: 60%;
  height: 100%;
  padding-left: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  h1 {
    font-weight: bold;
    height: 50px;
    font-size: 20px;
    letter-spacing: 1px;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    svg {
      margin-right: 10px;
    }
  }
  h2 {
    font-weight: lighter;
    height: 50px;
    font-size: 20px;
    letter-spacing: 1px;
    color: #fff;
  }
  div {
    margin-top: 20px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    a {
      margin-right: 15px;
    }
  }
`;

export const ButtonPrevious = styled.button<ButtonPrevNextProps>`
  z-index: 93;
  background: transparent;
  border: 0;
  position: fixed;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  right: 4vw;
  top: ${props => (props.isMobile ? '120px' : '135px')};
  color: #fff;
  font-weight: lighter;
  font-size: 18px;
  svg {
    margin-left: 7px;
  }
`;

export const ButtonNext = styled.button<ButtonPrevNextProps>`
  z-index: 94;
  background: transparent;
  border: 0;
  position: fixed;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  right: 4vw;
  bottom: ${props => (props.isMobile ? '4vh' : '7vh')};
  color: #fff;
  font-weight: lighter;
  font-size: 18px;
  animation: pulse 3s 2s ease-out infinite;
  @keyframes pulse {
    0% {
      opacity: 1;
      transform: none;
    }
    50% {
      opacity: 0.8;
      transform: scale(0.9);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  }

  svg {
    margin-left: 7px;
  }
`;
