import React, { useState, useEffect } from 'react';
import CrossfadeImage from 'react-crossfade-image';

import {
  Container,
  Title,
  Content,
  ContentDescription,
  ContentPictures,
  ContentFadePictures
} from './styles';

import size from '../../../config/windowSizeConfig';

import adhara from '../../../assets/customers/adhara.png';
import alizz from '../../../assets/customers/alizz.png';
import antares from '../../../assets/customers/antares.png';
import bgv from '../../../assets/customers/bgv.png';
import ceat from '../../../assets/customers/ceat.png';
import ciapedras from '../../../assets/customers/ciapedras.png';
import guia from '../../../assets/customers/guia.png';
import imojel from '../../../assets/customers/imojel.png';
import iwis from '../../../assets/customers/iwis.png';
import lfquimica from '../../../assets/customers/lfquimica.png';
import lenhard from '../../../assets/customers/lenhard.png';
import nattubras from '../../../assets/customers/nattubras.png';
import opium from '../../../assets/customers/opium.png';
import padariahorn from '../../../assets/customers/padariahorn.png';
import neumann from '../../../assets/customers/neumann.png';
import pze from '../../../assets/customers/pze.png';
import robertajungblut from '../../../assets/customers/robertajungblut.png';
import safe from '../../../assets/customers/safe.png';
import spengler from '../../../assets/customers/spengler.png';
import toqueespecial from '../../../assets/customers/toqueespecial.png';

const Customers: React.FC = () => {
  const { isMobile } = size();
  const pictures = [
    adhara,
    antares,
    bgv,
    ceat,
    ciapedras,
    alizz,
    guia,
    imojel,
    iwis,
    lfquimica,
    lenhard,
    nattubras,
    opium,
    padariahorn,
    pze,
    robertajungblut,
    safe,
    spengler,
    toqueespecial,
    neumann,
  ];

  const [pictureActive, setPictureActive] = useState<number>(0);
    
  useEffect(() => {
    const counter = setInterval(() => {
      setPictureActive((t) => t === 19 ? t = 1 : t + 1);
    }, 4000);
    return () => {
      clearInterval(counter);
    };
  }, []);
  
  return (
    <Container>
      <Title isMobile={isMobile}>
        <h1>Nossos clientes</h1>
      </Title>
      <Content isMobile={isMobile}>
        {!isMobile ? (
          <>
            <ContentDescription>
              <h2>
                Veja alguns dos mais de 500 clientes que confiam e contribuem para
                o crescimento e sucesso do nosso trabalho
              </h2>
            </ContentDescription>
            <ContentPictures isMobile={isMobile}>
              <div>
                <div> <img src={pictures[9]} alt="customer0"/> </div>
                <div> <img src={pictures[2]} alt="customer1"/> </div>
                <div> <img src={pictures[1]} alt="customer2"/> </div>
              </div>
              <div> 
                <div> <img src={pictures[3]} alt="customer3"/> </div>
                <div> <img src={pictures[18]} alt="customer4"/> </div>
                <div> <img src={pictures[7]} alt="customer5"/> </div>
              </div>
              <div> 
                <div> <img src={pictures[6]} alt="customer6"/> </div>
                <div> <img src={pictures[5]} alt="customer7"/> </div>
                <div> <img src={pictures[8]} alt="customer8"/> </div>
              </div>
              {/*
              <div> <img src={pictures[9]} alt="customer9"/> </div>
              <div> <img src={pictures[10]} alt="customer10"/> </div>
              <div> <img src={pictures[11]} alt="customer11"/> </div>
              <div> <img src={pictures[12]} alt="customer12"/> </div>
              <div> <img src={pictures[13]} alt="customer13"/> </div>
              <div> <img src={pictures[14]} alt="customer14"/> </div>
              <div> <img src={pictures[15]} alt="customer15"/> </div>
              <div> <img src={pictures[16]} alt="customer16"/> </div>
              <div> <img src={pictures[17]} alt="customer17"/> </div>
              <div> <img src={pictures[18]} alt="customer18"/> </div>
              <div> <img src={pictures[19]} alt="customer19"/> </div>
              */}
            </ContentPictures>
          </>
        ) : (
        <ContentFadePictures isMobile={isMobile}>
          <div>
            <CrossfadeImage
              duration={2000}
              timingFunction="ease-out"
              src={pictures[pictureActive]}
              alt="customer1"
            />
          </div>
        </ContentFadePictures>
        )}
        
      </Content>
    </Container>
  );
};

export default Customers;
