import styled from 'styled-components';

interface TitleProps {
  isMobile: boolean | undefined;
}

interface ContentProps {
  isMobile: boolean | undefined;
}

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #273746;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 40px 0 40px;
`;

export const Title = styled.div<TitleProps>`
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  h1 {
    font-size: ${props => (props.isMobile ? '28px' : '40px')};
    padding: 0 5px;
    color: #fff;
    background: linear-gradient(to bottom, transparent 65%, #a53234 35%);
  }
`;

export const Content = styled.div<ContentProps>`
  width: 100%;
  height: 55%;
  display: flex;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
  align-items: center;
  justify-content: center;
`;

export const ContentBlockLeft = styled.div<ContentProps>`
  width: ${props => (props.isMobile ? '100%' : '50%')};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${props => (props.isMobile ? 'center' : 'flex-end')};
  padding-right: ${props => !props.isMobile && '25px'};
  h1 {
    font-weight: bold;
    margin: ${props => (props.isMobile ? '5px 0' : '20px 0')};
    font-size: ${props => (props.isMobile ? '14px' : '32px')};
    letter-spacing: 1px;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    svg {
      margin-right: 10px;
    }
  }
`;

export const ContentBlockRight = styled.div<ContentProps>`
  width: ${props => (props.isMobile ? '100%' : '50%')};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.isMobile ? 'flex-start' : 'center')};
  align-items: ${props => (props.isMobile ? 'center' : 'flex-start')};
  padding-left: ${props => !props.isMobile && '25px'};
  padding-top: ${props => !props.isMobile && '17px'};
  h2 {
    font-weight: lighter;
    height: ${props => !props.isMobile && '50px'};
    font-size: ${props => (props.isMobile ? '14px' : '20px')};
    letter-spacing: 1px;
    color: #fff;
  }
  div {
    margin-top: 20px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    a {
      margin-right: 15px;
    }
  }
`;

export const JoinUs = styled.div<ContentProps>`
  width: 100%;
  height: 5%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  h3 {
    font-weight: lighter;
    font-size: ${props => (props.isMobile ? '12px' : '14px')};
    letter-spacing: 1px;
    color: #fff;
  }
`;
