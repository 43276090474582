import React from 'react';
import { RiWhatsappLine } from 'react-icons/ri';
import { TiSocialFacebook, TiSocialLinkedin } from 'react-icons/ti';

import {
  Container,
  Title,
  Content,
  ContentBlockLeft,
  ContentBlockRight,
  JoinUs,
} from './styles';

import size from '../../../config/windowSizeConfig';

const Contact: React.FC = () => {
  const { isMobile } = size();

  return (
    <Container>
      <Title isMobile={isMobile}>
        <h1>Entre em contato</h1>
      </Title>
      <Content isMobile={isMobile}>
        <ContentBlockLeft isMobile={isMobile}>
          <h1>atendimento@3rsistemas.com.br</h1>
          <h1>
            <RiWhatsappLine size={isMobile ? 20 : 42} color="#fff" />
            (51) 9 9186-9950
          </h1>
          <h1>(51) 3712-7282</h1>
        </ContentBlockLeft>
        <ContentBlockRight isMobile={isMobile}>
          <h2>Estrela</h2>
          <h2>RS 129, 421. Arroio do Ouro</h2>
          <h2>Rio Grande do Sul, 95880-000</h2>
          <div>
            <a
              href="https://facebook.com/3rSistemas/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <TiSocialFacebook size={34} color="#fff" />
            </a>
            <a
              href="https://www.linkedin.com/company/3r-sistemas-personalizados"
              rel="noopener noreferrer"
              target="_blank"
            >
              <TiSocialLinkedin size={34} color="#fff" />
            </a>
          </div>
        </ContentBlockRight>
      </Content>
      <JoinUs isMobile={isMobile}>
        <h3>
          Quer fazer parte do nosso time ? Escreva para rafael@3rsistemas.com.br
        </h3>
      </JoinUs>
    </Container>
  );
};

export default Contact;
