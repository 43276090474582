import styled, { css } from 'styled-components';

import backgroundImage from '../../../assets/home_background.png';

interface ContainerProps {
  isMobile: boolean | undefined;
}

export const Container = styled.div<ContainerProps>`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.4),
      rgba(46, 44, 44, 0.4)
    ),
    url(${backgroundImage}) no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.isMobile ? 'center' : 'flex-start')};
  justify-content: center;
  h1 {
    font-size: ${props => (props.isMobile ? '24px' : '42px')};
    max-width: 850px;
    letter-spacing: 1px;
    line-height: 65px;
    color: #fff;
    ${props =>
      !props.isMobile &&
      css`
        margin-left: 40px;
      `}
    margin-bottom: 110px;
    ${props =>
      props.isMobile &&
      css`
        text-align: center;
      `}

    span {
      padding: 0 3px;
      font-size: ${props => (props.isMobile ? '24px' : '42px')};
      letter-spacing: 1px;
      color: #fff;
      background: linear-gradient(to bottom, transparent 65%, #a53234 35%);
    }
  }
`;
