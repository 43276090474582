import React from 'react';

import { Container } from './styles';

import size from '../../../config/windowSizeConfig';

const Home: React.FC = () => {
  const { isMobile }: { isMobile: boolean | undefined } = size();

  return (
    <Container isMobile={isMobile}>
      <h1>
        Transformamos suas
        {` `}
        <span>necessidades</span>
        {` `}
        em
        {` `}
        <span>soluções inteligentes</span>
      </h1>
    </Container>
  );
};

export default Home;
