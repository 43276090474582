import styled from 'styled-components';
import { shade } from 'polished';

interface TitleProps {
  isMobile: boolean | undefined;
}

interface ContentProps {
  isMobile: boolean | undefined;
}

interface ServiceDescriptionProps {
  isMobile: boolean | undefined;
  selected: number | undefined;
}

interface ButtonProps {
  isSelected: boolean | undefined;
  isMobile: boolean | undefined;
}

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #273746;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 40px 0 40px;
`;

export const Title = styled.div<TitleProps>`
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  h1 {
    font-size: ${props => (props.isMobile ? '32px' : '40px')};
    padding: 0 5px;
    color: #fff;
    background: linear-gradient(to bottom, transparent 65%, #a53234 35%);
  }
`;

export const Content = styled.div<ContentProps>`
  width: 100%;
  height: 55%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Services = styled.div<ContentProps>`
  width: 50%;
  height: 100%;
  display: flex;
  margin: ${props => (props.isMobile ? '4px' : '12px')};
  padding: ${props => (props.isMobile ? '12px' : '32px')};
  border-radius: 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #213140;
  h1 {
    font-size: ${props => (props.isMobile ? '14px' : '32px')};
    margin-top: 32px;
    text-align: center;
    color: white;
  }
  img {
    width: 50px;
  }
  p {
    color: #ffffff;
    font-size: ${props => (props.isMobile ? '14px' : '18px')};
    margin-top: ${props => (props.isMobile ? '12px' : '32px')};
    line-height: ${props => (props.isMobile ? '1.2' : '1.6')};
    text-align: center;
  }
  a {
    margin-top: ${props => (props.isMobile ? '12px' : '24px')};
    border-radius: 10px;
    border: 0;
    background: #a53234;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 35px;
    font-size: ${props => (props.isMobile ? '12px' : '18px')};
    transition: background-color 0.2s;
    color: #ffffff;
    text-decoration: none;
    &:hover {
      background: ${shade(0.2, '#a53234')};
    }
  }
  &:hover {
    background: ${shade(0.05, '#273746')};
  }
`;

export const ServicesButton = styled.button<ButtonProps>`
  /*
  width: ${props => (props.isMobile ? '50%' : '100%')};
  height: ${props => (props.isMobile ? '100%' : '40%')};
  padding: ${props => (props.isMobile ? '5px' : '0')};
  display: flex;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
  align-items: center;
  justify-content: center;
  border: 0;
  background: ${props => (props.isSelected ? '#213140' : 'transparent')};
  border-radius: 10px;
  & + button {
    margin: ${props => (props.isMobile ? '0 0 0 10px' : '20px 0 0 0')};
  }
  transition: background-color 0.2s;
  &:hover {
    background: ${shade(0.05, '#273746')};
  }
  */
  border-radius: 10px;
  border: 0;
  background: #213140;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 80%;
  transition: background-color 0.2s;
  &:hover {
    background: ${shade(0.05, '#273746')};
  }
  h1 {
    margin-top: 12px;
  }
`;

export const ServicesButtonIcon = styled.div`
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 52px;
    height: 55px;
  }
`;

export const ServicesButtonDescription = styled.div<ContentProps>`
  width: ${props => (props.isMobile ? '100%' : '75%')};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  h1 {
    color: #fff;
    font-size: ${props => (props.isMobile ? '14px' : '24px')};
    line-height: 1.3;
    text-align: left;
  }
  h2 {
    margin-top: 15px;
    color: #fff;
    font-size: ${props => (props.isMobile ? '12px' : '14px')};
    font-weight: normal;
  }
`;

export const ServiceDescription = styled.div<ServiceDescriptionProps>`
  width: ${props => (props.isMobile ? '100%' : '60%')};
  height: ${props => (props.isMobile ? '70%' : '100%')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${props => (props.isMobile ? 'flex-start' : 'center')};
  padding: ${props => (props.isMobile ? '24px 5px' : '0 96px')};
  h1 {
    width: 100%;
    color: #fff;
    font-size: ${props => (props.isMobile ? '18px' : '42px')};
    line-height: 1.6;
    text-align: left;
    font-weight: normal;
    text-align: justify;
    strong {
      color: #fff;
      font-size: ${props => (props.isMobile ? '18px' : '42px')};
      line-height: 1.6;
      text-align: left;
      font-weight: bold;
    }
    a {
      color: #fff;
      font-size: ${props => (props.isMobile ? '18px' : '42px')};
      line-height: 1.6;
      text-align: left;
      font-weight: bold;
      text-decoration: none;
      transition: color 0.2s;
      &:hover {
        color: ${shade(0.3, '#fff')};
      }
    }
  }
  img {
    width: ${props =>
    // eslint-disable-next-line no-nested-ternary
    props.isMobile ? '200px' : props.selected === 0 ? '370px' : '270px'};
    margin: 0 0 20px 0;
  }
`;
