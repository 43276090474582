import React from 'react';

import { Container } from './styles';

import size from '../../config/windowSizeConfig';

const Page404: React.FC = () => {
  const { isMobile }: { isMobile: boolean | undefined } = size();

  return (
    <Container isMobile={isMobile}>
      <h1>404</h1>
      <a href="/">Voltar para o site</a>
    </Container>
  );
};

export default Page404;
